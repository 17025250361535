/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  /* font-family: "DM Sans", sans-serif; */
}

div{
  font-family: 'Poppins', sans-serif;
}

option {
  color: black;
}

.apexcharts-text tspan , .apexcharts-legend-text{
  font-family: 'Poppins', sans-serif!important;
}

.sidebar {
  width: 80px;
  height: 100%;
  background-color: #333;
  transition: width 0.3s ease-in-out;
  overflow: hidden;
}


.sidebar.open {
  width: 300px;
}

.toggle-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* ===== Scrollbar CSS ===== */
/* For WebKit browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: #d0d8d8;
  padding: 3px;
}



  